<template>
  <section>
    <!-- #enderecos -->
    <div v-if="regionalDepartmentOptions.length > 0">
      <enderecos
        v-for="(unidadeEndereco, index) in value.unidade_endereco"
        :key="unidadeEndereco.id_empresa_endereco"
        :label="`Endereço ${index + 1}`"
        :unidadeEndereco="unidadeEndereco"
        :regionalDepartmentOptions="regionalDepartmentOptions"
        @setUnidadeEndereco="setUnidadeEndereco"
      />
    </div>
    <b-col v-else>carregando as informações...</b-col>
  </section>
</template>
<script>
import {
  BCard,
  BFormInput,
  BCol,
  BRow,
  BButton,
  BOverlay,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";
import VueCleave from "vue-cleave-component";
import enderecos from "./partes/enderecos.vue";

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    BCol,
    BRow,
    BOverlay,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    vSelect,
    ValidationProvider,
    VueCleave,
    enderecos,
  },

  props: {
    errorApi: {
      type: Object,
      required: true,
    },
    naoIndustria: {
      type: Boolean,
      required: true,
    },
    cidade: {
      type: Object,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
    unidade: {
      type: Object,
      required: false,
    },
  },

  computed: {
    valorDiferenciadoComputado() {
      return this.value.valor_diferenciado;
    },
  },

  data() {
    return {
      casoEdicao: false,
      tiposPreco: [],
      erros: "",
      removeCategoryItem: "Convênio",
      regionalDepartmentOptions: [],
    };
  },

  mounted() {
    this.buscaDepartamentos();
  },

  methods: {
    setUnidadeEndereco(unidadeEndereco) {
      this.$emit("setUnidadeEndereco", unidadeEndereco);
    },

    buscaDepartamentos() {
      this.$http
        .get(this.$api.departamento(), { params: { todos: true } })
        .then(({ data }) => {
          this.regionalDepartmentOptions = data;
        });
    },
  },

  watch: {
    value() {
      this.$emit("input", this.value);
    },

    valorDiferenciadoComputado() {
      if (!this.valorDiferenciadoComputado) {
        this.$emit("update:valorDiferenciadoFalso", null);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.radio-inline-spacing-valor {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > * {
    margin-right: 1.5rem;
    margin-bottom: 0.2rem;
  }
}
.label_endereco {
  font-size: 16px;
  font-weight: 900;
}
</style>