<template>
  <b-overlay
    variant="white"
    :show="loading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-row v-if="!loading">
      <b-col class="mb-2" cols="12">
        <span class="label_endereco" v-text="label"></span>
      </b-col>
      <b-col xl="4" cols="4">
        <b-form-group
          label="Departamento Regional"
          label-for="departamento-regional-select"
        >
          <v-select
            id="departamento-regional-select"
            :options="departamentos"
            v-model="departamentoSelecionado"
            placeholder="Selecione o departamento regional"
            label="descricao"
            :clearable="false"
            item-text="descricao"
            item-value="id_departamento"
            :disabled="departamentoSelecionado && regioes.length > 0"
          >
            <span slot="no-options">Nenhuma opção selecionável.</span>
          </v-select>
        </b-form-group>
      </b-col>

      <b-col xl="4" cols="4">
        <b-form-group label="Região" label-for="regiao-select">
          <v-select
            id="regiao-select"
            :options="regioes"
            v-model="regiaoSelecionada"
            placeholder="Selecione região"
            label="descricao"
            :clearable="false"
            item-text="descricao"
            item-value="id_regiao"
          >
            <span slot="no-options">Nenhuma opção selecionável.</span>
          </v-select>
        </b-form-group>
      </b-col>

      <b-col xl="4" cols="4">
        <b-form-group
          label="Unidade Operacional"
          label-for="unidade-operacional-select"
        >
          <v-select
            id="unidade-operacional-select"
            :options="unidades"
            v-model="unidadeSelecionada"
            placeholder="Selecione a unidade operacional"
            label="descricao_unidade"
            :clearable="false"
            item-text="descricao_unidade"
            item-value="id_unidade"
            @input="(event) => setUnidadeEndereco"
          >
            <span slot="no-options">Nenhuma opção selecionável.</span>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BOverlay, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BCol,
    BRow,
    vSelect,
    BFormGroup,
    BOverlay,
  },

  props: {
    unidadeEndereco: {
      required: true,
    },
    label: {
      required: true,
    },
    regionalDepartmentOptions: {
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      departamentos: [],
      departamentoSelecionado: null,
      regioes: [],
      regiaoSelecionada: null,
      unidades: [],
      unidadeSelecionada: null,
    };
  },

  mounted() {    
    this.setFormOptions();
  },
  created() {
    this.loading = false;
  },

  methods: {
    buscaRegioesPorDepartamento() {
      return this.departamentoSelecionado == undefined
        ? []
        : this.$http
            .get(this.$api.regiao(), {
              params: {
                ativo: true,
                id_departamento: this.departamentoSelecionado.id_departamento,
                todos: true,
              },
            })
            .then(({ data }) => {
                this.regioes = data;
                return data;
            });
    },

    buscaUnidadesPorRegiao() {
      return this.regiaoSelecionada == undefined
        ? []
        : this.$http
            .get(this.$api.unidade(), {
              params: {
                ativo: true,
                id_regiao: this.regiaoSelecionada.id_regiao,
                todos: true,
              },
            })
            .then(({ data }) => data);
    },

    async setFormOptions() {
      this.setDepartamentos();

      this.setRegioes(await this.buscaRegioesPorDepartamento());
      if (this.regiaoSelecionada != undefined) {
        this.setUnidades(await this.buscaUnidadesPorRegiao());
      }
    },

    setDepartamentos() {
        this.departamentos = this.regionalDepartmentOptions
        this.departamentoSelecionado = this.departamentos.find(
          (item) => item.id_departamento == this.unidadeEndereco.id_departamento
        );
    },

    setRegioes(regioes) {
      this.regioes = regioes;
      this.regiaoSelecionada = this.regioes.find(
        (item) => item.id_regiao == this.unidadeEndereco.id_regiao
      );

    },

    setUnidades(unidades) {
      this.unidades = unidades;
      this.unidadeSelecionada = this.unidades.find(
        (item) => item.id_unidade == this.unidadeEndereco.id_unidade
      );

    },

    setUnidadeEndereco() {
      if (this.unidadeSelecionada != null) {
        this.$emit("setUnidadeEndereco", {
          id_empresa_endereco: this.unidadeEndereco.id_empresa_endereco,
          id_departamento: this.unidadeEndereco.id_departamento,
          id_regiao: this.regiaoSelecionada.id_regiao,
          id_unidade: this.unidadeSelecionada.id_unidade,
          id_cidade: this.unidadeSelecionada.id_cidade
        });
      }
    },
  },

  watch: {
    departamentoSelecionado(){
      this.regiaoSelecionada = null
      this.unidadeSelecionada = null
      this.buscaRegioesPorDepartamento();
    },
    regiaoSelecionada: {
      deep: true,
      async handler(_newVal, oldVal) {
        if (oldVal != null || oldVal == undefined) {
          this.setUnidades(await this.buscaUnidadesPorRegiao());
        }
      },
    },
    unidadeSelecionada() {
      this.setUnidadeEndereco();
    },
  },
};
</script>

<style lang="scss" scoped>
.label_endereco {
  font-size: 16px;
  font-weight: 900;
}
</style>