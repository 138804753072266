<template>
  <div class="content-wrapper">
    <PageHeader screenName="Empresas" :linkItems="linkItems" />

    <PendenciaModal
      :showModal="modalPendencia.showModal"
      @confirmed="closeModalPendencia"
    />

    <UnidadeEnderecoEmpresaModal
      :showModal="modalUnidadeEnderecoEmpresa.showModal"
      @confirmed="closeModalUnidadeEnderecoEmpresa"
    />

    <ConfirmModal
      itemIdentifier="usuário"
      :modalShow="modal.showModal"
      :typeModal="modal.typeModal"
      @confirmed="redirecionarLista"
      @canceled="closeModalConfirm"
    />

    <SucessoModal
      :modalShow="modalSucesso.showModal"
      :typeModal="modalSucesso.typeModal"
      :caseModal="modalSucesso.caseModal"
      @confirmed="redirecionarLista"
    />

    <ErrorModal
      :modalShow="modalError.showModal"
      :caseModal="modalError.caseModal"
      @confirmed="redirecionarLista"
    />

    <TimedAlert
      variant="warning"
      message="O estado selecionado é diferente do seu departamento regional!"
      :show="departamentoAlert"
    />
    <TimedAlert
      variant="warning"
      message="Esta empresa tem pendências em seu registro com o SESI, é necessário sua validação para um cadastro completo."
      :show="pendingStatus == 'Pendente' ? true : false"
    />

    <b-card>
      <b-overlay :show="loadingShow" rounded="lg" opacity="0.6" class="w-100">
        <alertaTodasAbas :show="situacaoCadastro == 'Incompleto'" />
        <validation-observer ref="simpleRules">
          <b-tabs
            pills
            v-model="tabIndex"
            :vertical="vertical"
            class="displayResponsivo"
          >
            <!-- Tab: Dados principais -->
            <b-tab>
              <template #title>
                <span
                  v-if="iconeErro.dadosPrincipais && tabIndex == 0"
                  class="icone-erro-ativo"
                >
                  <feather-icon icon="AlertCircleIcon" size="18" />
                </span>
                <span v-else-if="iconeErro.dadosPrincipais" class="icone-erro">
                  <feather-icon icon="AlertCircleIcon" size="18" />
                </span>
                <span class="font-weight-bold">Dados principais</span>
              </template>
              <empresa-tab-dados-principais
                :key="populated.dadosPrincipais"
                ref="tabDadosPrincipais"
                :mudancaEstado.sync="cnpj"
                :travaSaving.sync="saving"
                :apagaErro400Api.sync="errorApi.dadosPrincipais.documento"
                :errorApi="errorApi.dadosPrincipais"
                :cnpjFaturamento.sync="mesmoCnpjFaturamento"
                :dataNascimentoForm.sync="form.dadosPrincipais.data_nascimento"
                :nomeCpfForm.sync="form.dadosPrincipais.nome"
                :generoForm.sync="form.dadosPrincipais.genero"
                :ativarIconeAba.sync="iconeErro.dadosPrincipais"
                :alterarAba.sync="tabIndex"
                :disabledAll="disabledAllPrincipalData"
                :edicao="true"
                v-model="form.dadosPrincipais"
                class="mt-2 mb-2 pt-75"
              />
            </b-tab>

            <!-- Tab: Dados complementares -->
            <b-tab>
              <template #title>
                <span
                  v-if="iconeErro.dadosComplementares && tabIndex == 1"
                  class="icone-erro-ativo"
                >
                  <feather-icon icon="AlertCircleIcon" size="18" />
                </span>
                <span
                  v-else-if="iconeErro.dadosComplementares"
                  class="icone-erro"
                >
                  <feather-icon icon="AlertCircleIcon" size="18" />
                </span>
                <span class="font-weight-bold">Dados complementares</span>
              </template>
              <empresa-tab-dados-complementares
                :key="populated.dadosComplementares"
                ref="tabDadosComplementares"
                :pessoaEmpresa="cnpj"
                class="mt-2 mb-2 pt-75"
                v-model="form.dadosComplementares"
                :tipoEmpresa="tipoEmpresaSelecionada"
                @getTipoEmpresa="updateTipoEmpresa"
                :errorApi="errorApi.dadosComplementares"
                :naoIndustria="verificaSeEhNaoIndustria"
                :valorDiferenciadoFalso.sync="
                  form.dadosComplementares.id_tipo_preco
                "
              />
            </b-tab>

            <!-- Tab: Faturamento -->
            <b-tab v-if="cnpj">
              <template #title>
                <span
                  v-if="iconeErro.faturamento && tabIndex == 2"
                  class="icone-erro-ativo"
                >
                  <feather-icon icon="AlertCircleIcon" size="18" />
                </span>
                <span v-else-if="iconeErro.faturamento" class="icone-erro">
                  <feather-icon icon="AlertCircleIcon" size="18" />
                </span>
                <span class="font-weight-bold">Faturamento</span>
              </template>
              <empresa-tab-dados-faturamento
                :key="populated.dadosFaturamento"
                ref="tabDadosFaturamento"
                class="mt-2 mb-2 pt-75"
                v-model="form.faturamentoPrevio"
                :mesmoCnpjFaturamento="mesmoCnpjFaturamento"
                :errorApi="errorApi.faturamento"
                :getCnpjFaturamento.sync="form.faturamento.cnpj_faturamento"
              />
            </b-tab>

            <!-- Tab: Endereço -->
            <b-tab>
              <template #title>
                <span
                  v-if="iconeErro.endereco && tabIndex == 2 && !cnpj"
                  class="icone-erro-ativo"
                >
                  <feather-icon icon="AlertCircleIcon" size="18" />
                </span>
                <span
                  v-else-if="iconeErro.endereco && tabIndex == 3 && cnpj"
                  class="icone-erro-ativo"
                >
                  <feather-icon icon="AlertCircleIcon" size="18" />
                </span>
                <span v-else-if="iconeErro.endereco" class="icone-erro">
                  <feather-icon icon="AlertCircleIcon" size="18" />
                </span>
                <span class="font-weight-bold">Endereço</span>
              </template>
              <empresa-tab-dados-endereco
                :key="populated.dadosEndereco"
                ref="tabDadosEndereco"
                class="mt-2 mb-2 pt-75"
                :preencheLogradouro.sync="form.endereco.logradouro"
                :preencheBairro.sync="form.endereco.bairro"
                :travaSaving.sync="saving"
                :cidade="cidadeSelecionada"
                :observerAditionalAddresses.sync="observerAditionalAddresses"
                :errorApi="errorApi.endereco"
                :userData="userData"
                :isEdit="true"
                :idCompany="idCompany"
                @disparaAlertaEstadoDepartamento="verificaDepartamento"
                @getCidade="updateCidade"
                @addedAddress="prepareEnderecoCompleto"
                @updateEnderecoTabContato="atualizaEnderecoTabContato"
                v-model="form.endereco"
              />
            </b-tab>

            <!-- Tab: Contato -->
            <b-tab>
              <template #title>
                <span v-if="iconeErro.contatoPrincipal && tabIndex == 3 && !cnpj" class="icone-erro-ativo"><feather-icon icon="AlertCircleIcon" size="18" /></span>
                <span v-else-if="iconeErro.contatoPrincipal && tabIndex == 4 && cnpj" class="icone-erro-ativo"><feather-icon icon="AlertCircleIcon" size="18" /></span>
                <span v-else-if="iconeErro.contatoPrincipal" class="icone-erro"><feather-icon icon="AlertCircleIcon" size="18" /></span>
                <span class="font-weight-bold">Contato</span>
              </template>
              <empresa-tab-dados-contato
                :key="populated.dadosContato"
                ref="tabDadosContato"
                class="mt-2 mb-2 pt-75"
                :errorApiContatoPrincipal="errorApi.contatoPrincipal"
                :errorApiEndereco.sync="errorApiEnderecoContato"
                :dadosContatoEndereco="form.enderecoCompleto"
                v-model="form.contatoPrincipal"
              />
            </b-tab>

            <!-- Tab: Atendimento -->
            <b-tab v-if="!adminEmpresa" @click="clickAtendimento">
              <template #title>
                <span
                  v-if="iconeErro.atendimento && tabIndex == 4 && !cnpj"
                  class="icone-erro-ativo"
                >
                  <feather-icon icon="AlertCircleIcon" size="18" />
                </span>
                <span
                  v-else-if="iconeErro.atendimento && tabIndex == 5 && cnpj"
                  class="icone-erro-ativo"
                >
                  <feather-icon icon="AlertCircleIcon" size="18" />
                </span>
                <span v-else-if="iconeErro.atendimento" class="icone-erro">
                  <feather-icon icon="AlertCircleIcon" size="18" />
                </span>
                <span class="font-weight-bold">Atendimento</span>
              </template>
              <empresa-tab-dados-atendimento
                :key="populated.dadosAtendimento"
                class="mt-2 mb-2 pt-75"
                ref="tabDadosAtendimento"
                v-model="form.atendimento"
                :errorApi="errorApi.atendimento"
                :cidade="cidadeSelecionada"
                :naoIndustria="verificaSeEhNaoIndustria"
                :unidade="unidadeSelecionada"
                :travaSaving.sync="saving"
                @getUnidade="updateUnidade"
                @verificaDepartamento="verificaDepartamento"
                @setUnidadeEndereco="setUnidadeEndereco"
              />
            </b-tab>
          </b-tabs>
        </validation-observer>
        <section class="buttons">
          <b-button
            id="save-empresa"
            class="mr-2 mt-3"
            variant="custom-blue"
            @click.prevent="validationForm()"
            :disabled="saving"
          >
            <feather-icon icon="CheckIcon" class="mr-50" />
            <span class="align-middle">Salvar empresa</span>
            <span
              v-if="saving"
              class="spinner-border spinner-border-sm ml-1"
              role="status"
              aria-hidden="true"
            >
            </span>
          </b-button>
          <b-button
            id="cancel-edit-empresa"
            :disabled="saving"
            variant="outline-danger"
            @click.prevent="openModalConfirm"
            class="mt-3"
          >
            <feather-icon icon="XIcon" class="mr-50" />
            <span class="align-middle">Cancelar</span>
          </b-button>
        </section>
      </b-overlay>
    </b-card>
  </div>
</template>
<script>

import { BCard, BTab, BTabs, BButton, BOverlay } from "bootstrap-vue";
import EmpresaTabDadosPrincipais from "@/views/custom-pages/empresa/tabs-empresa/Empresa-tab-dados-principais.vue";
import EmpresaTabDadosComplementares from "@/views/custom-pages/empresa/tabs-empresa/Empresa-tab-dados-complementares.vue";
import EmpresaTabDadosContato from "@/views/custom-pages/empresa/tabs-empresa/Empresa-tab-contato.vue";
import EmpresaTabDadosAtendimento from "@/views/custom-pages/empresa/tabs-empresa/Empresa-tab-atendimento.vue";
import EmpresaTabDadosEndereco from "@/views/custom-pages/empresa/tabs-empresa/Empresa-tab-endereco.vue";
import EmpresaTabDadosFaturamento from "@/views/custom-pages/empresa/tabs-empresa/Empresa-tab-faturamento.vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import ConfirmModal from "@/views/components/custom/modals/ConfirmModal.vue";
import SucessoModal from "@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue";
import PendenciaModal from "@/views/components/custom/modals/empresa/PendenciaModal.vue";
import alertaTodasAbas from "@/views/components/custom/timed-alert/alertaTodasAbas.vue";
import TimedAlert from "@/views/components/custom/timed-alert/TimedAlert.vue";
import ErrorModal from "@/views/components/custom/modals/ErroIdInexistenteModal.vue";
import { ValidationObserver } from "vee-validate";
import handleErrorsService from "./Services/HandleErrors";
import { getIdEmpresa } from "@core/utils/store/getStore";
import { setIdEmpresa, setCidadeEstadoEnderecoEmpresa } from "@core/utils/store/setStore";
import { tipoEmpresa } from "@/enum/empresa";
import { actions, subjects } from "@/libs/acl/rules";
import { getUserData } from "@/auth/utils";
import UnidadeEnderecoEmpresaModal from "@/views/components/custom/modals/empresa/UnidadeEnderecoEmpresaModal.vue";
export default {
  title: "Editar empresa",

  components: {
    BCard,
    BTab,
    BTabs,
    BButton,
    ValidationObserver,
    EmpresaTabDadosPrincipais,
    EmpresaTabDadosComplementares,
    EmpresaTabDadosFaturamento,
    EmpresaTabDadosEndereco,
    EmpresaTabDadosAtendimento,
    EmpresaTabDadosContato,
    PageHeader,
    ConfirmModal,
    SucessoModal,
    PendenciaModal,
    UnidadeEnderecoEmpresaModal,
    BOverlay,
    ErrorModal,
    alertaTodasAbas,
    TimedAlert
  },

  computed: {
    arrayComplementarVigiarObrigatorios() {
      const array = {
        nomeFantasia: this.form.dadosPrincipais.nome_fantasia,
        razaoSocial: this.form.dadosPrincipais.razao_social,
        nomeCompleto: this.form.dadosPrincipais.nome,
        genero: this.form.dadosPrincipais.genero,
        inscricaoEstadual: this.form.dadosPrincipais.inscricao_estadual,
        dataNascimento: this.form.dadosPrincipais.data_nascimento,
        cnae: this.form.dadosComplementares.cnae,
        tipoEmpresaSelecionado: this.tipoEmpresaSelecionada,
        radioCnpjFaturamento:
          this.form.faturamentoPrevio.radioMesmoCnpjFaturamento,
        cnpjFaturamento: this.form.faturamentoPrevio.cnpjFaturamento,
        cep: this.form.endereco.cep,
        logradouro: this.form.endereco.logradouro,
        bairro: this.form.endereco.bairro,
        cidadeSelecionada: this.cidadeSelecionada,
        nome: this.form.contatoPrincipal.nome,
        cargo: this.form.contatoPrincipal.cargo,
        email: this.form.contatoPrincipal.email,
        telefone: this.form.contatoPrincipal.telefone,
        observerAditionalAddresses: this.observerAditionalAddresses
      };

      if (!this.adminEmpresa) {
        array.unidadeSelecionada = this.unidadeSelecionada;
      }

      return array;
    },

    arrayVerificacaoCnpj() {
      const array = {
        nomeFantasia: this.form.dadosPrincipais.nome_fantasia,
        razaoSocial: this.form.dadosPrincipais.razao_social,
        cnae: this.form.dadosComplementares.cnae,
        tipoEmpresaSelecionado: this.tipoEmpresaSelecionada,
        cnpjFaturamento: this.form.faturamentoPrevio.cnpjFaturamento,
        cep: this.form.endereco.cep,
        logradouro: this.form.endereco.logradouro,
        bairro: this.form.endereco.bairro,
        cidadeSelecionada: this.cidadeSelecionada,
        nome: this.form.contatoPrincipal.nome,
        cargo: this.form.contatoPrincipal.cargo,
        email: this.form.contatoPrincipal.email,
        telefone: this.form.contatoPrincipal.telefone,
        observerAditionalAddresses: this.observerAditionalAddresses
      };

      if (!this.adminEmpresa) {
        array.unidadeSelecionada = this.unidadeSelecionada;
      }

      return array;
    },

    arrayVerificacaoCnpjSemFaturamento() {
      return {
        nomeFantasia: this.form.dadosPrincipais.nome_fantasia,
        razaoSocial: this.form.dadosPrincipais.razao_social,
        cnae: this.form.dadosComplementares.cnae,
        tipoEmpresaSelecionado: this.tipoEmpresaSelecionada,
        cep: this.form.endereco.cep,
        logradouro: this.form.endereco.logradouro,
        bairro: this.form.endereco.bairro,
        cidadeSelecionada: this.cidadeSelecionada,
        nome: this.form.contatoPrincipal.nome,
        cargo: this.form.contatoPrincipal.cargo,
        email: this.form.contatoPrincipal.email,
        telefone: this.form.contatoPrincipal.telefone,
        observerAditionalAddresses: this.observerAditionalAddresses
      };
    },

    arrayVerificacaoCpf() {
      const array = {
        nomeCompleto: this.form.dadosPrincipais.nome,
        genero: this.form.dadosPrincipais.genero,
        inscricaoEstadual: this.form.dadosPrincipais.inscricao_estadual,
        dataNascimento: this.form.dadosPrincipais.data_nascimento,
        cnae: this.form.dadosComplementares.cnae,
        tipoEmpresaSelecionado: this.tipoEmpresaSelecionada,
        cep: this.form.endereco.cep,
        logradouro: this.form.endereco.logradouro,
        bairro: this.form.endereco.bairro,
        cidadeSelecionada: this.cidadeSelecionada,
        nome: this.form.contatoPrincipal.nome,
        cargo: this.form.contatoPrincipal.cargo,
        email: this.form.contatoPrincipal.email,
        telefone: this.form.contatoPrincipal.telefone,
        observerAditionalAddresses: this.observerAditionalAddresses
      };

      if (!this.adminEmpresa) {
        array.unidadeSelecionada = this.unidadeSelecionada;
      }

      return array;
    },

    vertical() {
      return this.tamanhoTelaTempoReal < 980;
    },

    verificaSeEhNaoIndustria() {
      if (this.tipoEmpresaSelecionada == null) {
        return false;
      }

      return (
        this.tipoEmpresaSelecionada &&
        this.tipoEmpresaSelecionada.descricao == "Não Indústria"
      );
    },

    formEmpresa() {
      return this.form;
    },
  },

  data() {
    return {
      cnpj: true,
      tamanhoTelaTempoReal: 0,
      mesmoCnpjFaturamento: "",
      tabIndex: 0,
      departamentoAlert: false,
      cidadeSelecionada: null,
      tipoEmpresaSelecionada: null,
      unidadeSelecionada: null,
      situacaoCadastro: null,
      userData: getUserData(),
      populated: {
        dadosPrincipais: false,
        dadosComplementares: false,
        dadosFaturamento: false,
        dadosEndereco: false,
        dadosContato: false,
        dadosAtendimento: false,
      },
      dataEmpresa: null,
      qtdEnderecos: 0,
      form: {
        key: Math.random(),
        dadosPrincipais: {
          documento: "",
          nome_fantasia: null,
          razao_social: null,
          nome: null,
          inscricao_estadual: null,
          data_nascimento: null,
          genero: null,
          nome_mae: null,
          id_nacionalidade: null,
        },
        dadosComplementares: {
          cnae: null,
          fpas: null,
          numero_de_trabalhadores: null,
          id_tipo_empresa: null,
          base_nacional: false,
          id_grupo: null,
          descricao_grupo: null,
          id_operador: null,
          descricao_operador: null,
          valor_diferenciado: false,
          id_tipo_preco: null,
          possui_convenio: false,
        },
        faturamento: {
          cnpj_faturamento: "",
        },
        endereco: {
          cep: null,
          numero: null,
          ponto_referencia: null,
          id_cidade: null,
          logradouro: null,
          bairro: null,
          id_empresa_endereco: null,
          contato: {
            nome: null,
            cargo: null,
            email: null,
            telefone: null,
            telefone_secundario: null
          }
        },
        contatoPrincipal:{
          nome: null,
          cargo: null,
          email: null,
          telefone: null,
          telefone_secundario: null,
        },
        atendimento: {
          unidade_endereco: null,
        },
        faturamentoPrevio: {
          radioMesmoCnpjFaturamento: true,
          cnpjFaturamento: null,
        },
        enderecoCompleto: []
      },
      errorApi: {
        dadosPrincipais: {
          documento: null,
          nome_fantasia: null,
          razao_social: null,
          nome: null,
          inscricao_estadual: null,
          data_nascimento: null,
          genero: null,
          nome_mae: null,
          id_nacionalidade: "",
          error: ''
        },
        dadosComplementares: {
          cnae: null,
          fpas: null,
          numero_de_trabalhadores: null,
          id_tipo_empresa: null,
          valor_diferenciado: null,
          id_tipo_preco: null,
          possui_convenio: null,
          id_grupo: null,
          id_operador: null,
          base_nacional: null,
          error: ''
        },
        faturamento: {
          cnpj_faturamento: null,
          error: "",
        },
        endereco: {
          cep: null,
          numero: null,
          ponto_referencia: null,
          id_cidade: null,
          logradouro: null,
          bairro: null,
          id_empresa_endereco: null,
          error: "",
        },
        contatoPrincipal: {
          nome: null,
          cargo: null,
          email: null,
          telefone: null,
          telefone_secundario: null,
          error: "",
        },
        atendimento: {
          unidade_endereco: null,
          error: "",
        },
      },
      modalError: {
        showModal: false,
        typeModal: "error",
        caseModal: "idInexistente",
      },
      modal: {
        showModal: false,
        typeModal: "danger",
      },
      modalSucesso: {
        showModal: false,
        typeModal: "success",
        caseModal: "edicao",
      },
      modalPendencia: {
        showModal: false,
        typeModal: "danger",
      },
      modalUnidadeEnderecoEmpresa: {
        showModal: false
      },
      saving: false,
      loadingShow: true,
      alert: {
        errorMessage: "",
        show: false,
      },
      linkItems: [
        {
          name: "Empresas",
          routeName: "empresa-list",
        },
        {
          name: "Editar: aguarde, buscando dados...",
          active: true,
        },
      ],
      iconeErro: {
        dadosPrincipais: false,
        dadosComplementares: false,
        faturamento: false,
        endereco: false,
        contatoPrincipal: false,
        atendimento: false
      },
      acceptedTerm: true,
      ufDepartamento: null,
      oldUnit: null,
      casoEdicao: true,
      idCompany: null,
      observerAditionalAddresses: true,
      disabledAllPrincipalData: true,
      pendingStatus: "",
      savedCnaeAndIdTipoEmpresa: {
        cnae: null,
        id_tipo_empresa: null,
      },
      errorApiEnderecoContato: [],
      adminEmpresa: this.verifyPermissions(actions.ATUALIZAR),
      permissionNational: this.$can(actions.VISUALIZAR, subjects.TIPO_ATUACAO_EMPRESA_BASE_NACIONAL),
      total_colaboradores: 0
    }
  },

  mounted() {
    if (this.verificaAcessoUsuarioPorDepartamento()) {
      this.modalErroDepartamento();
      return;
    }

    if (
      (getIdEmpresa() === null || typeof getIdEmpresa() == "undefined") &&
      typeof this.$route.params.idEmpresa == "undefined"
    ) {
      this.openModalError();
      return;
    }

    if (typeof this.$route.params.idEmpresa != "undefined") {
      if (this.$route.params.idEmpresa !== getIdEmpresa()) {
        setIdEmpresa(this.$route.params.idEmpresa);
      }
    }

    this.$http
      .get(this.$api.getShowAndEditEmpresa(getIdEmpresa()))
      .then(({ data }) => {
        this.idCompany = getIdEmpresa();
        this.dataEmpresa = data
        this.preencheDadosAbas(data);
      });

    let that = this
    this.tamanhoTelaTempoReal = window.innerWidth
    window.onresize = function() {
      that.tamanhoTelaTempoReal = window.innerWidth
    }

    this.prepareEnderecoCompleto();

    setCidadeEstadoEnderecoEmpresa(null);
  },

  methods: {
    clickAtendimento() {
      this.dataEmpresa.enderecos.forEach(address => {
        if(address.unidade.length > 1) {
          this.modalUnidadeEnderecoEmpresa.showModal = true
        }
      })
    },

    openModalPendencia() {
      this.modalPendencia.showModal = true;
    },

    openModalUnidadeEnderecoEmpresa() {
      this.modalUnidadeEnderecoEmpresa.showModal = true
    },

    closeModalUnidadeEnderecoEmpresa() {
      this.modalUnidadeEnderecoEmpresa.showModal = false
    },

    closeModalPendencia() {
      this.modalPendencia.showModal = false;
      this.$router.push({
        name: "empresa-list",
      });
    },

    validationForm() {
      this.escondeIconesErrosAba();
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.saving = true;
          this.editarEmpresa();
        } else {
          let arrayErrosIniciais = this.$refs.simpleRules.errors;
          let arrayNomeErros = [];
          for (const key in arrayErrosIniciais) {
            if (arrayErrosIniciais[key][0] != null)
              arrayNomeErros.push([key][0]);
          }
          this.verificaEMostraErrosIniciais(arrayNomeErros);
        }
      });
    },

    async preencheDadosAbas(dados) {
      this.situacaoCadastro = dados['situacao_cadastro'];
      this.total_colaboradores = dados['total_colaboradores'];
      this.pendingStatus = dados["situacao"];
      this.acceptedTerm = dados["id_termo"] ? true : false;
      
      this.preenchimentoDadosPrincipais(dados);
      await this.preenchimentoDadosComplementares(dados);
      this.$refs.tabDadosComplementares.casoEdicao = false;
      if (dados["cnpj"]) {
        this.preenchimentoFaturamento(dados);
      }
      if (dados["enderecos"].length > 0) {
        await this.preenchimentoPrimeiroEndereco(dados["enderecos"][0]);
        this.$refs.tabDadosEndereco.casoEdicao = false;
        if (!this.adminEmpresa) {
          this.$refs.tabDadosAtendimento.casoEdicao = false;
        }
        await this.fillAdditionalAddresses(dados["enderecos"]);
      }
      this.preenchimentoContato(dados);
      if (!this.adminEmpresa) {
        await this.preenchimentoAtendimento(dados);
      }
      this.loadingShow = false;
    },

    preenchimentoDadosPrincipais(dadosPrincipais) {
      this.verifyPermissionsForTabDadosPrincipaisEdit();
      this.$refs.tabDadosPrincipais.documentoError = 'inicio'
      this.$refs.tabDadosPrincipais.inputDataNascimentoError = 'inicio'
      if(dadosPrincipais['cnpj']){
        this.cnpj = true
        this.$refs.tabDadosPrincipais.documentoCnpj = true
        this.$refs.tabDadosPrincipais.documentoCpf = false
        this.form.dadosPrincipais.documento = dadosPrincipais['cnpj'] ?? dadosPrincipais['cnpj']
        this.form.dadosPrincipais.nome_fantasia = dadosPrincipais['nome_fantasia'] ?? dadosPrincipais['nome_fantasia']
        this.form.dadosPrincipais.razao_social = dadosPrincipais['razao_social'] ?? dadosPrincipais['razao_social']
        this.linkItems[1].name = dadosPrincipais['nome_fantasia'] ?? 'Editar: '+dadosPrincipais['nome_fantasia']
      }else{
        this.cnpj = false
        this.$refs.tabDadosPrincipais.documentoCnpj = false
        this.$refs.tabDadosPrincipais.documentoCpf = true
        this.form.dadosPrincipais.documento = dadosPrincipais['cpf'] ?? dadosPrincipais['cpf']
        this.form.dadosPrincipais.nome = dadosPrincipais['nome'] ?? dadosPrincipais['nome']
        this.form.dadosPrincipais.genero = dadosPrincipais['genero'] ?? dadosPrincipais['genero']
        this.form.dadosPrincipais.inscricao_estadual = dadosPrincipais['inscricao_estadual'] ?? dadosPrincipais['inscricao_estadual']
        this.form.dadosPrincipais.data_nascimento = dadosPrincipais['data_nascimento'] ?? dadosPrincipais['data_nascimento']
        this.form.dadosPrincipais.nome_mae = dadosPrincipais['nome_mae'] ?? dadosPrincipais['nome_mae']
        this.form.dadosPrincipais.id_nacionalidade = dadosPrincipais['id_nacionalidade'] ?? dadosPrincipais['id_nacionalidade']
        this.form.dadosPrincipais.descricao_nacionalidade = dadosPrincipais['descricao_nacionalidade'] ?? dadosPrincipais['descricao_nacionalidade']
        this.$refs.tabDadosPrincipais.setDataNascimentoPicker()
        this.linkItems[1].name = dadosPrincipais['nome'] ?? 'Editar: '+dadosPrincipais['nome']
      }
      this.populated.dadosPrincipais = !this.populated.dadosPrincipais;
    },

    preenchimentoDadosComplementares(dadosComplementares) {
      this.$refs.tabDadosComplementares.casoEdicao = true;
      this.form.dadosComplementares.base_nacional = dadosComplementares['base_nacional']
      this.form.dadosComplementares.id_grupo = dadosComplementares['id_grupo']
      this.form.dadosComplementares.descricao_grupo = dadosComplementares['descricao_grupo']
      this.form.dadosComplementares.id_operador = dadosComplementares['id_operador']
      this.form.dadosComplementares.descricao_operador = dadosComplementares['descricao_operador']
      this.form.dadosComplementares.cnae =
        dadosComplementares["cnae"] ?? dadosComplementares["cnae"];
      this.form.dadosComplementares.fpas =
        dadosComplementares["fpas"] ?? dadosComplementares["fpas"];
      this.form.dadosComplementares.numero_de_trabalhadores =
        dadosComplementares["numero_de_trabalhadores"] ??
        dadosComplementares["numero_de_trabalhadores"];

      this.form.dadosComplementares.valor_diferenciado =
        dadosComplementares["valor_diferenciado"] ??
        dadosComplementares["valor_diferenciado"];

      this.form.dadosComplementares.id_tipo_preco =
        dadosComplementares["id_tipo_preco"] &&
        dadosComplementares["id_tipo_empresa"] &&
        dadosComplementares["id_tipo_empresa"] == tipoEmpresa.NAO_INDUSTRIA
          ? dadosComplementares["id_tipo_preco"]
          : null;

      this.form.dadosComplementares.possui_convenio =
        dadosComplementares["possui_convenio"] ??
        dadosComplementares["possui_convenio"];

      if (
        dadosComplementares["id_tipo_empresa"] &&
        dadosComplementares["tipo_empresa_descricao"]
      ) {
        this.tipoEmpresaSelecionada = {
          id_tipo_empresa: dadosComplementares["id_tipo_empresa"],
          descricao: dadosComplementares["tipo_empresa_descricao"],
        };
      }
      this.savedCnaeAndIdTipoEmpresa = {
        cnae: dadosComplementares["cnae"] ?? dadosComplementares["cnae"],
        id_tipo_empresa:
          dadosComplementares["id_tipo_empresa"] ??
          dadosComplementares["id_tipo_empresa"],
      };
      this.populated.dadosComplementares = !this.populated.dadosComplementares;
    },

    preenchimentoFaturamento(dados) {
      if (dados["cnpj_faturamento"] == dados["cnpj"]) {
        this.form.faturamentoPrevio.radioMesmoCnpjFaturamento = true;
      } else {
        this.form.faturamentoPrevio.radioMesmoCnpjFaturamento = false;
        this.form.faturamentoPrevio.cnpjFaturamento = dados["cnpj_faturamento"];
      }
      this.populated.dadosFaturamento = !this.populated.dadosFaturamento;
    },

    async preenchimentoPrimeiroEndereco(dadosEndereco) {
      if (dadosEndereco["cep"]) {
        this.$refs.tabDadosEndereco.casoEdicao = true;
        this.form.endereco.cep = dadosEndereco["cep"];
      }
      this.form.endereco.numero =
        dadosEndereco["numero"] ?? dadosEndereco["numero"];
      this.form.endereco.ponto_referencia =
        dadosEndereco["ponto_referencia"] ?? dadosEndereco["ponto_referencia"];
      if (dadosEndereco["estadoUF"] && dadosEndereco["estadoNome"]) {
        this.$refs.tabDadosEndereco.estadoSelecionado = {
          nome: dadosEndereco["estadoNome"],
          uf: dadosEndereco["estadoUF"],
        };
        await this.$refs.tabDadosEndereco.buscaCidades(
          dadosEndereco["estadoUF"]
        );
        if (dadosEndereco["id_cidade"] && dadosEndereco["cidadeNome"]) {
          if (!this.adminEmpresa) {
            this.$refs.tabDadosAtendimento.casoEdicao = true;
          }
          this.cidadeSelecionada = {
            id_cidade: dadosEndereco["id_cidade"],
            nome: dadosEndereco["cidadeNome"],
            uf: dadosEndereco["estadoUF"],
          };
        }
      }
      this.form.endereco.logradouro = dadosEndereco['logradouro'] ?? dadosEndereco['logradouro']
      this.form.endereco.bairro = dadosEndereco['bairro'] ?? dadosEndereco['bairro']
      this.form.endereco.id_empresa_endereco = dadosEndereco['id_empresa_endereco'] ?? dadosEndereco['id_empresa_endereco']
      this.form.endereco.cidade = dadosEndereco['cidadeNome'] ?? dadosEndereco['cidadeNome'];
      this.form.endereco.estado = dadosEndereco['estadoNome'] ?? dadosEndereco['estadoNome'];
      this.populated.dadosEndereco = !this.populated.dadosEndereco;

      if(dadosEndereco.contato) {
        this.form.endereco.contato.nome = dadosEndereco.contato.nome;
        this.form.endereco.contato.cargo = dadosEndereco.contato.cargo;
        this.form.endereco.contato.email = dadosEndereco.contato.email;
        this.form.endereco.contato.telefone = dadosEndereco.contato.telefone;
        this.form.endereco.contato.telefone_secundario = dadosEndereco.contato.telefone_secundario;

        this.form.endereco.contato.logradouro = dadosEndereco['logradouro'] ?? dadosEndereco['logradouro'];
        this.form.endereco.contato.numero = dadosEndereco['numero'] ?? dadosEndereco['numero'];
        this.form.endereco.contato.bairro = dadosEndereco['bairro'] ?? dadosEndereco['bairro'];
        this.form.endereco.contato.cidade = dadosEndereco['cidadeNome'] ?? dadosEndereco['cidadeNome'];
        this.form.endereco.contato.estado = dadosEndereco['estadoNome'] ?? dadosEndereco['estadoNome'];
        this.form.endereco.contato.cep = dadosEndereco['cep'] ?? dadosEndereco['cep'];
      }
    },

    fillAdditionalAddresses(dadosEndereco) {
      this.$refs.tabDadosEndereco.editAddAddress = true;
      for (let i = 1; i < dadosEndereco.length; i++) {
        this.$refs.tabDadosEndereco.addAddress();
        if (dadosEndereco[i]["cep"]) {
          this.$refs.tabDadosEndereco.casoEdicao = true;
          this.$refs.tabDadosEndereco.addresses[i - 1].cep =
            dadosEndereco[i]["cep"];
        }
        this.$refs.tabDadosEndereco.addresses[i - 1].numero =
          dadosEndereco[i]["numero"] ?? dadosEndereco[i]["numero"];
        this.$refs.tabDadosEndereco.addresses[i - 1].ponto_referencia =
          dadosEndereco[i]["ponto_referencia"] ??
          dadosEndereco[i]["ponto_referencia"];
        if (dadosEndereco[i]["estadoUF"] && dadosEndereco[i]["estadoNome"]) {
          this.$refs.tabDadosEndereco.addressesInternalUse[
            i - 1
          ].estadoSelecionado = {
            nome: dadosEndereco[i]["estadoNome"],
            uf: dadosEndereco[i]["estadoUF"],
          };
          this.$refs.tabDadosEndereco.buscaCidades(
            dadosEndereco[i]["estadoUF"],
            [i - 1]
          );
          if (dadosEndereco[i]["id_cidade"] && dadosEndereco[i]["cidadeNome"]) {
            this.$refs.tabDadosEndereco.addressesInternalUse[i - 1].cidade = {
              id_cidade: dadosEndereco[i]["id_cidade"],
              nome: dadosEndereco[i]["cidadeNome"],
              uf: dadosEndereco[i]["estadoUF"],
            };
          }
        }
        this.$refs.tabDadosEndereco.addresses[i - 1].logradouro = dadosEndereco[i]["logradouro"] ?? dadosEndereco[i]["logradouro"];
        this.$refs.tabDadosEndereco.addresses[i - 1].bairro = dadosEndereco[i]["bairro"] ?? dadosEndereco[i]["bairro"];
        this.$refs.tabDadosEndereco.addresses[i - 1].id_empresa_endereco = dadosEndereco[i]["id_empresa_endereco"] ?? dadosEndereco[i]["id_empresa_endereco"];
        this.$refs.tabDadosEndereco.addresses[i - 1].cidade = dadosEndereco[i]["cidadeNome"] ?? dadosEndereco[i]["cidadeNome"];
        this.$refs.tabDadosEndereco.addresses[i - 1].estado = dadosEndereco[i]["estadoNome"] ?? dadosEndereco[i]["estadoNome"];

        this.$refs.tabDadosEndereco.casoEdicao = false;
    
        if(dadosEndereco[i].contato) {
          this.$refs.tabDadosEndereco.addresses[i-1].contato.nome = dadosEndereco[i].contato.nome;
          this.$refs.tabDadosEndereco.addresses[i-1].contato.cargo = dadosEndereco[i].contato.cargo;
          this.$refs.tabDadosEndereco.addresses[i-1].contato.email = dadosEndereco[i].contato.email;
          this.$refs.tabDadosEndereco.addresses[i-1].contato.telefone = dadosEndereco[i].contato.telefone;
          this.$refs.tabDadosEndereco.addresses[i-1].contato.telefone_secundario = dadosEndereco[i].contato.telefone_secundario;

          this.$refs.tabDadosEndereco.addresses[i-1].contato.logradouro = dadosEndereco[i]["logradouro"] ?? dadosEndereco[i]["logradouro"];
          this.$refs.tabDadosEndereco.addresses[i-1].contato.numero = dadosEndereco[i]["numero"] ?? dadosEndereco[i]["numero"];
          this.$refs.tabDadosEndereco.addresses[i-1].contato.bairro = dadosEndereco[i]["bairro"] ?? dadosEndereco[i]["bairro"];
          this.$refs.tabDadosEndereco.addresses[i-1].contato.cidade = dadosEndereco[i]["cidadeNome"] ?? dadosEndereco[i]["cidadeNome"];
          this.$refs.tabDadosEndereco.addresses[i-1].contato.estado = dadosEndereco[i]["estadoNome"] ?? dadosEndereco[i]["estadoNome"];
          this.$refs.tabDadosEndereco.addresses[i-1].contato.cep = dadosEndereco[i]["cep"] ?? dadosEndereco[i]["cep"];
        }
      }
    },

    preenchimentoContato(dadosContato){
      const contatoPrincipal = dadosContato['empresa_contato_principal'][0];

      if(contatoPrincipal) {
        this.form.contatoPrincipal.nome = contatoPrincipal['nome'] ?? contatoPrincipal['nome']
        this.form.contatoPrincipal.cargo = contatoPrincipal['tipo_contato']['descricao'] ?? contatoPrincipal['tipo_contato']['descricao']
        this.form.contatoPrincipal.email = contatoPrincipal['email'] ?? contatoPrincipal['email']
        this.form.contatoPrincipal.telefone = contatoPrincipal['telefone'] ?? contatoPrincipal['telefone']
        this.form.contatoPrincipal.telefone_secundario = contatoPrincipal['telefone_secundario'] ?? contatoPrincipal['telefone_secundario']
      }
    },

    async preenchimentoAtendimento(dados) {
      this.form.atendimento.unidade_endereco = dados.enderecos.map(
        (endereco) => {
          return {
            id_empresa_endereco: endereco.id_empresa_endereco,
            id_departamento: endereco.id_departamento,
            id_regiao: endereco.id_regiao,
            id_unidade: endereco.id_unidade,
            id_cidade: endereco.id_cidade
          };
        }
      );
      this.populated.dadosAtendimento = !this.populated.dadosAtendimento;
    },

    editarEmpresa() {
      this.formataDadosParaEdicao();
      this.erasePreexistingErrors();
      this.escondeIconesErrosAba();

      const parameters = {
        empresaTipo: this.cnpj ? "cnpj" : "cpf",
        dadosComplementares: this.form.dadosComplementares,
        endereco: this.form.enderecoCompleto,
        contatoPrincipal: this.form.contatoPrincipal,
      };

      if (this.cnpj) {
        parameters.cnpj_faturamento = this.form.faturamento.cnpj_faturamento;
      }

      parameters.atendimento = this.form.atendimento;

      if (!this.disabledAllPrincipalData) {
        const cloneDadosPrincipais = Object.assign(
          {},
          this.form.dadosPrincipais
        );
        delete cloneDadosPrincipais.documento;
        delete cloneDadosPrincipais.descricao_nacionalidade
        parameters.dadosPrincipais = cloneDadosPrincipais;
        parameters.dadosPrincipais.id_nacionalidade = parameters.dadosPrincipais.id_nacionalidade != undefined 
          ? parameters.dadosPrincipais.id_nacionalidade 
          : null
      }
    
      this.$http
        .put(this.$api.getShowAndEditEmpresa(getIdEmpresa()), parameters)
        .then(() => {
          this.saving = false;

          this.verifyPendencyOrSucess();

          this.cleanForm();
        })
        .catch(({ response: { data } }) => {
          handleErrorsService.handleErrosEmpresa(
            data,
            this.errorApi,
            this.$refs.tabDadosEndereco.addressesErrorApi,
            this.form.enderecoCompleto.length,
            this.errorApiEnderecoContato
          );
          this.redirecionadorErro();
          this.saving = false;
        });
    },

    erasePreexistingErrors() {
      const tabDadosErrorApi = [
        this.errorApi.dadosPrincipais,
        this.errorApi.dadosComplementares,
        this.errorApi.faturamento,
        this.errorApi.endereco,
        this.errorApi.contatoPrincipal,
        this.errorApi.atendimento,
      ];
      this.valoresDeArraysParaNull(tabDadosErrorApi);
      this.$refs.simpleRules.reset();
    },

    cleanForm() {
      this.$refs.tabDadosPrincipais.inputDataNascimento = "";
      this.cnpj = true;
      this.mesmoCnpjFaturamento = "";
      this.cidadeSelecionada = null;
      this.tipoEmpresaSelecionada = null;
      this.unidadeSelecionada = null;
      this.$refs.tabDadosEndereco.estadoSelecionado = null;
      this.$refs.tabDadosEndereco.addresses = [];
      this.$refs.tabDadosEndereco.addressesInternalUse = [];
      this.$refs.tabDadosEndereco.addressesErrorApi = [];
      if (!this.adminEmpresa) {
        this.$refs.tabDadosAtendimento.departamentoAtendimento = null;
        this.$refs.tabDadosAtendimento.regiaoSelecionada = null;
      }
      this.observerAditionalAddresses = true;
      const tabDados = [
        this.form.dadosComplementares,
        this.errorApi.dadosComplementares,
        this.form.faturamento,
        this.errorApi.faturamento,
        this.form.atendimento,
        this.errorApi.atendimento,
        this.form.faturamentoPrevio,
      ];
      this.valoresDeArraysParaNull(tabDados);

      this.$refs.simpleRules.reset();
    },

    valoresDeArraysParaNull(arrays) {
      for (const iterator of arrays) {
        this.valorDeArrayParaNull(iterator);
      }
    },

    valorDeArrayParaNull(item) {
      Object.keys(item).forEach((key) => {
        if (key == "radioMesmoCnpjFaturamento") {
          item[key] = true;
        } else if (item[key] === true || item[key] === false) {
          item[key] = false;
        } else {
          item[key] = "";
        }
      });
    },

    redirecionarLista() {
      this.$router.push({ name: "empresa-list" });
    },

    openModalError() {
      this.modalError.showModal = true;
    },

    openModalConfirm() {
      this.modal.showModal = true;
    },

    closeModalConfirm() {
      this.modal.showModal = false;
    },

    openModalSucesso() {
      this.modalSucesso.showModal = true;
    },

    updateCidade(cidade) {
      this.cidadeSelecionada = cidade;
    },

    updateTipoEmpresa(tipoEmpresaUpdate) {
      this.tipoEmpresaSelecionada = tipoEmpresaUpdate;
    },

    updateUnidade(unidade) {
      this.unidadeSelecionada = unidade;
    },

    verificaAcessoUsuarioPorDepartamento(){
      if(this.permissionNational){
        return false;
      }
      if(
        !this.verifyPermissions(actions.ATUALIZAR) &&
        this.userData.departamento &&
        this.$route.params.uf &&
        this.$route.params.uf.includes(this.userData.departamento.uf)
      ) {
        return true;
      }
    },

    formataDadosParaEdicao() {
      this.verificaEPreencheCnpjFaturamento();
      delete this.form.dadosComplementares.descricao_grupo
      delete this.form.dadosComplementares.descricao_operador
      this.form.dadosComplementares.numero_de_trabalhadores = this.form
        .dadosComplementares.numero_de_trabalhadores
        ? this.form.dadosComplementares.numero_de_trabalhadores.toString()
        : "";
      this.form.dadosComplementares.id_tipo_empresa = this
        .tipoEmpresaSelecionada
        ? this.tipoEmpresaSelecionada.id_tipo_empresa
        : null;
      this.form.endereco.id_cidade = this.cidadeSelecionada
        ? this.cidadeSelecionada.id_cidade
        : null;
      this.form.dadosComplementares.id_tipo_preco = this.form
        .dadosComplementares.valor_diferenciado
        ? this.form.dadosComplementares.id_tipo_preco
        : null;
      this.prepareIdCidadeMultipleAddresses();
      this.prepareEnderecoCompleto();
    },

    prepareIdCidadeMultipleAddresses() {
      for (const [
        index,
        value,
      ] of this.$refs.tabDadosEndereco.addressesInternalUse.entries()) {
        this.$refs.tabDadosEndereco.addresses[index].id_cidade =
          value.cidade && value.cidade.id_cidade
            ? value.cidade.id_cidade
            : null;
      }
    },

    prepareEnderecoCompleto() {
      this.form.enderecoCompleto = [];
      this.form.enderecoCompleto.push(this.form.endereco);
      for (const value of this.$refs.tabDadosEndereco.addresses) {
        if (!Object.values(value).every((x) => x === null || x === "")) {
          this.form.enderecoCompleto.push(value);
        }
      }
      this.preparaObjetoErroContatoEndereco();
    },

    atualizaEnderecoTabContato() {
      Object.values(this.form.enderecoCompleto).forEach(endereco => { 
        endereco.contato.logradouro = endereco.logradouro;
        endereco.contato.numero = endereco.numero;
        endereco.contato.bairro = endereco.bairro;
        endereco.contato.cidade = endereco.cidade;
        endereco.contato.estado = endereco.estado;
        endereco.contato.cep = endereco.cep;
      })
      let idsEmpresaEndereco = this.form.enderecoCompleto.map(endereco => endereco.id_empresa_endereco)
      
      if(this.form.atendimento.unidade_endereco){
        this.form.atendimento.unidade_endereco.map((unidade, index) => {
          if(!idsEmpresaEndereco.includes(unidade.id_empresa_endereco)){
            this.form.atendimento.unidade_endereco.splice(index, 1);
          }
        })
      }
      this.form.key = Math.random();
    },

    preparaObjetoErroContatoEndereco() {
      this.errorApiEnderecoContato = [];
      let contatoObjectAmount = 0;

      while(contatoObjectAmount < this.form.enderecoCompleto.length) {
        contatoObjectAmount++;
        this.errorApiEnderecoContato.push({
          nome: null,
          cargo: null,
          email: null,
          telefone: null,
          telefone_secundario: null,
          error: null
        })
      }
    },

    verificaEPreencheCnpjFaturamento() {
      if (this.cnpj) {
        if (this.form.faturamentoPrevio.radioMesmoCnpjFaturamento) {
          this.form.faturamento.cnpj_faturamento = this.$helpers.somenteNumeros(
            JSON.stringify(this.form.dadosPrincipais.documento)
          );
        } else {
          this.form.faturamento.cnpj_faturamento =
            this.form.faturamentoPrevio.cnpjFaturamento;
        }
      }
    },

    redirecionadorErro() {
      const emptyDadosPrincipais = Object.values(this.errorApi.dadosPrincipais).every(
        (x) => x === null || x === ""
      );
      const emptyDadosComplementares = Object.values(this.errorApi.dadosComplementares).every(
        (x) => x === null || x === ""
      );
      const emptyFaturamento = Object.values(this.errorApi.faturamento).every(
        (x) => x === null || x === ""
      );
      const emptyEndereco = Object.values(this.errorApi.endereco).every(
        (x) => x === null || x === ""
      );
      const emptyAditionalAddresses = this.verifyApiErrorAditionalAddresses(
        this.$refs.tabDadosEndereco.addressesErrorApi
      );
      const emptyContato = Object.values(this.errorApi.contatoPrincipal).every(
        (x) => x === null || x === ""
      );
      const emptyAtendimento = Object.values(this.errorApi.atendimento).every(
        (x) => x === null || x === ""
      );
      const emptyEnderecoContato = this.errorApiEnderecoContato.map((x) => {
        return Object.values(x).map(x => x === null || x === "").includes(false);
      })

      const arrayVerificadores = {
        dadosPrincipais: emptyDadosPrincipais,
        dadosComplementares: emptyDadosComplementares,
        faturamento: emptyFaturamento,
        endereco: emptyEndereco,
        contatoEndereco: !emptyEnderecoContato.includes(true),
        aditionalAddresses: emptyAditionalAddresses,
        contatoPrincipal: emptyContato,
        atendimento: emptyAtendimento,
      };

      this.mostraIconeErroAba(arrayVerificadores);
      if (this.cnpj) {
        this.redirecionaAbaErroCnpj(arrayVerificadores);
      } else {
        this.redirecionaAbaErroCpf(arrayVerificadores);
      }
    },

    verifyApiErrorAditionalAddresses(arrayApiErrorAditionalAddresses) {
      for (const aditionalAddress of arrayApiErrorAditionalAddresses) {
        delete aditionalAddress.contato;
        if (
          !Object.values(aditionalAddress).every((x) => x === null || x === "")
        ) {
          return false;
        }
      }
      return true;
    },

    mostraIconeErroAba(emptyDados) {
      this.iconeErro.dadosPrincipais = !emptyDados.dadosPrincipais;
      this.iconeErro.dadosComplementares = !emptyDados.dadosComplementares;
      this.iconeErro.faturamento = !emptyDados.faturamento;
      this.iconeErro.endereco = !emptyDados.endereco || !emptyDados.aditionalAddresses;
      this.iconeErro.contatoPrincipal = !emptyDados.contatoPrincipal || !emptyDados.contatoEndereco;
      this.iconeErro.atendimento = !emptyDados.atendimento;
    },

    redirecionaAbaErroCnpj(emptyDados) {
      if (!emptyDados.dadosPrincipais) {
        this.tabIndex = 0;
      } else if (!emptyDados.dadosComplementares) {
        this.tabIndex = 1;
      } else if (!emptyDados.faturamento) {
        this.tabIndex = 2;
      } else if (!emptyDados.endereco || !emptyDados.aditionalAddresses) {
        this.tabIndex = 3;
      } else if (!emptyDados.contatoPrincipal || !emptyDados.contatoEndereco) {
        this.tabIndex = 4;
      } else if (!emptyDados.atendimento) {
        this.tabIndex = 5;
      }
    },

    redirecionaAbaErroCpf(emptyDados) {
      if (!emptyDados.dadosPrincipais) {
        this.tabIndex = 0;
      } else if (!emptyDados.dadosComplementares) {
        this.tabIndex = 1;
      } else if (!emptyDados.endereco || !emptyDados.aditionalAddresses) {
        this.tabIndex = 2;
      } else if (!emptyDados.contatoPrincipal || !emptyDados.contatoEndereco) {
        this.tabIndex = 3;
      } else if (!emptyDados.atendimento) {
        this.tabIndex = 4;
      }
    },

    escondeIconesErrosAba() {
      this.iconeErro.dadosPrincipais = false;
      this.iconeErro.dadosComplementares = false;
      this.iconeErro.faturamento = false;
      this.iconeErro.endereco = false;
      this.iconeErro.contatoPrincipal = false;
      this.iconeErro.atendimento = false;
    },

    verificaEMostraErrosIniciais(arrayNomesErros) {
      let abasComErros = {
        dadosPrincipais: true,
        dadosComplementares: true,
        faturamento: true,
        endereco: true,
        aditionalAddresses: true,
        contatoPrincipal: true,
        atendimento: true,
        contatoEndereco: true,
      };
      abasComErros = this.retornaObjetoAbasComErroPrimario(
        arrayNomesErros,
        abasComErros
      );
      this.mostraIconeErroAba(abasComErros);
      if (this.cnpj) {
        this.redirecionaAbaErroCnpj(abasComErros);
      } else {
        this.redirecionaAbaErroCpf(abasComErros);
      }
    },

    retornaObjetoAbasComErroPrimario(arrayNomesErros, abasComErros) {
      for (const prop in arrayNomesErros) {
        this.abasComErrosDocumentos(arrayNomesErros[prop], abasComErros);
        if (arrayNomesErros[prop].startsWith("CEP") && abasComErros.endereco) {
          abasComErros.endereco = false;
        }
        if (
          (arrayNomesErros[prop] == "Telefone" ||
            arrayNomesErros[prop] == "TelefoneSecundario") &&
          abasComErros.contatoPrincipal
        ) {
          abasComErros.contatoPrincipal = false;
        }
      }
      return abasComErros;
    },

    abasComErrosDocumentos(arrayNomesErros, abasComErros) {
      if (
        (arrayNomesErros == "Documento" ||
          arrayNomesErros == "NomeFantasia" ||
          arrayNomesErros == "RazaoSocial") &&
        abasComErros.dadosPrincipais
      ) {
        abasComErros.dadosPrincipais = false;
      }
      if (
        (arrayNomesErros == "CNAE" || arrayNomesErros == "FPAS") &&
        abasComErros.dadosComplementares
      ) {
        abasComErros.dadosComplementares = false;
      }
      if (arrayNomesErros == "CNPJFaturamento" && abasComErros.faturamento) {
        abasComErros.faturamento = false;
      }
    },

    verificacaoArrayEmpresaContemNulo(arrayVerificacao) {
      for (const prop in arrayVerificacao) {
        if (this.verificadorRetornaErroCasoNuloVazio(arrayVerificacao[prop])) {
          return 'Incompleto';
        }
      }
      return true;
    },

    verificadorRetornaErroCasoNuloVazio(prop) {
      if (prop === null || prop === "") {
        return true;
      }
    },

    verifyCNAERange(twoNumbers) {
      return !!(twoNumbers >= 5 && twoNumbers <= 33);
    },

    verifyPermissions(action) {
      return this.$can(actions[action], subjects.EMPRESAS_AE);
    },

    verifyPermissionsForTabDadosPrincipaisEdit() {
      this.disabledAllPrincipalData = !this.$can(
        actions.ATUALIZAR,
        subjects.EMPRESAS_DADOS_MINIMOS
      );
    },
    
    verificaDepartamento(objetoDepartamento) {
      if (
        this.userData.departamento &&
        objetoDepartamento &&
        this.userData.departamento.uf &&
        this.userData.departamento.uf != objetoDepartamento.uf
      ) {
        setTimeout(() => {
          this.departamentoAlert = true;
        }, 100);
      }
      this.departamentoAlert = false;
    },

    verifyPendencyOrSucess() {
      if (
        this.verifyPermissions(actions.ATUALIZAR) &&
        this.form.dadosComplementares.cnae &&
        this.tipoEmpresaSelecionada &&
        this.tipoEmpresaSelecionada.descricao &&
        this.tipoEmpresaSelecionada.descricao === "Indústria" &&
        !this.verifyCNAERange(
          this.form.dadosComplementares.cnae.substring(0, 2)
        )
      ) {
        if (
          this.savedCnaeAndIdTipoEmpresa.cnae !=
            this.form.dadosComplementares.cnae ||
          this.savedCnaeAndIdTipoEmpresa.id_tipo_empresa !=
            this.form.dadosComplementares.id_tipo_empresa
        ) {
          this.openModalPendencia();
        } else if (this.pendingStatus == "Pendente") {
          this.openModalPendencia();
        } else {
          this.openModalSucesso();
        }
      } else {
        this.openModalSucesso();
      }
    },

    setUnidadeEndereco(unidadeEndereco) {
      let index = this.form.atendimento.unidade_endereco.findIndex(
        (item) =>
          item.id_empresa_endereco === unidadeEndereco.id_empresa_endereco
      );
      this.form.atendimento.unidade_endereco[index] = unidadeEndereco;
      this.form.key = Math.random();
    },
  },

  watch: {
    arrayComplementarVigiarObrigatorios() {
      if (!this.loadingShow) {
        if (
          this.cnpj &&
          this.form.faturamentoPrevio.radioMesmoCnpjFaturamento
        ) {
          this.situacaoCadastro = this.verificacaoArrayEmpresaContemNulo(
            this.arrayVerificacaoCnpjSemFaturamento
          );
        } else if (
          this.cnpj &&
          !this.form.faturamentoPrevio.radioMesmoCnpjFaturamento
        ) {
          this.situacaoCadastro = this.verificacaoArrayEmpresaContemNulo(
            this.arrayVerificacaoCnpj
          );
        } else if (!this.cnpj) {
          this.situacaoCadastro = this.verificacaoArrayEmpresaContemNulo(
            this.arrayVerificacaoCpf
          );
        } else {
          this.situacaoCadastro = true;
        }
      }
    },

    cnpj: function (newVal) {
      if (!newVal) {
        this.tipoEmpresaSelecionada =
          this.$refs.tabDadosComplementares.tiposEmpresa.find(function (
            objeto
          ) {
            if (objeto.descricao == "Não Indústria") {
              return {
                id_tipo_empresa: objeto.id_tipo_empresa,
                descricao: objeto.descricao,
              };
            } else {
              return null;
            }
          });
      } else {
        this.tipoEmpresaSelecionada = null;
      }
    },

    "form.dadosComplementares.cnae": {
      handler: function (newCnae) {
        if (
          !this.$refs.tabDadosComplementares.casoEdicao &&
          newCnae.length == 7 &&
          this.verifyCNAERange(parseInt(newCnae[0] + newCnae[1])) &&
          this.cnpj
        ) {
          this.tipoEmpresaSelecionada =
            this.$refs.tabDadosComplementares.tiposEmpresa.find(function (
              objeto
            ) {
              if (objeto.descricao == "Indústria") {
                return {
                  id_tipo_empresa: objeto.id_tipo_empresa,
                  descricao: objeto.descricao,
                };
              }
            });
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.font_size_label_empresa {
  font-size: 15px;
}
.nav-pills .nav-link.active {
  border-color: #2772c0;
  background-color: #2772c0;
}
.icone-erro {
  color: red;
  margin-right: 5px;
}
.icone-erro-ativo {
  color: white;
  margin-right: 5px;
}
@media (max-width: 750px) {
  .displayResponsivo {
    display: block;
  }
}
</style>
